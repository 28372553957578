/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/resound/'], ['en', 'https://www.hear.com/hearing-aids/brands/resound/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/resound/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/resound/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing impairment has many causes and is not necessarily age-related. ReSounds’ innovative and modern hearing solutions bring people of all ages back into the world of sound. Since 1943, the Danish company has been developing high-quality hearing systems that let even their wearers forget that they have hearing aids on."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In April 2014, the Danish company ReSound introduced LiNX to the global public. The first hearing aid that can be directly connected with your iPhone, iPad or iPod.\nThis feature turns the ReSound LiNX into one of today’s top products in terms of sound and speech quality. It is also one of the world’s leading products for connectivity with external multimedia devices. While expensive and cumbersome accessory devices were necessary in the past to play music, movies and make calls through a hearing aid, today the ReSound LiNX lets you control everything via app."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "powerful-but-discreet---the-resound-linx",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#powerful-but-discreet---the-resound-linx",
    "aria-label": "powerful but discreet   the resound linx permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Powerful but discreet - the ReSound LiNX²"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The iPhone hearing aid is visually attractive for its very small and discreet design. Yet it is enormously powerful and covers almost all hearing impairment needs.With the ReSound app on your cell phone, you can control the hearing aid directly adjusting the sounds and sound programs as required. The hearing aid also remembers the places you chose particular programs for and will change to them automatically. In other words, your hearing aid will recognize your weekly choir practice or the evening’s visit to a restaurant and select the suitable program for noise suppression. One of our hearing aid experts will gladly advise you on all the options this unique hearing aid provides."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In spring 2015 Resound introduced the ReSound LiNX² – the follower of the previous existing ReSound LiNX. And soon after its introduction ReSound was able to provide an additional so called “ReSound Relief App” which connects the Linx² Square directly with the smartphone. The focus of this app is the handling of ear noises (tinnitus) which can be regulated and reduced through given stress-free sounds or through self-selected music. Gladly one of our hearing aids specialist is willing to provide advice with all the possibilities which this unique hearing aid may hold for you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "experiencing-everyday-life-naturally-with-resound-hearing-solutions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#experiencing-everyday-life-naturally-with-resound-hearing-solutions",
    "aria-label": "experiencing everyday life naturally with resound hearing solutions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Experiencing everyday life naturally with ReSound hearing solutions"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Every day brings new challenges and situations that we have to readapt to. Our hearing aids are our constant companions here. They must meet our highest demands and greatest expectations. Hearing aids by Danish manufacturer ReSound, can be worn on a day-to-day basis, and be used extremely flexibly. The modern nano-coating, can withstand the highest everyday stresses and the sophisticated microphone and radio technology provides all-round natural hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "wirelessly-connected-with-resound-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wirelessly-connected-with-resound-hearing-aids",
    "aria-label": "wirelessly connected with resound hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wirelessly connected with ReSound hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The connectivity options from ReSound are based on 2.4 GHz Wireless Technology™. This technology enables the transfer of signals across distances of several meters. Disruptive remote controls, worn in close proximity to the hearing aids, are now obsolete."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Unite™ Audio Beamer, for instance, is easily connected wirelessly to the TV, to the hearing aids and transmits pure sound directly to the hearing aids’ loudspeakers. Thanks to the Unite™ Telephone Clip+, the hearing aids can even be operated via your own smartphone, so any modifications to the settings of the hearing aids remain completely unnoticed by others. With the development of innovative connectivity options, ReSound ensures that the young and young-at-heart can use all options of state-of-the-art technology without compromise."), "\n", React.createElement(ButtonCta, {
    copy: "GET RESOUND HEARING AIDS NOW",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/resound-device.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "resound-linx",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-linx",
    "aria-label": "resound linx permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound LiNX"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ReSound LiNX 3D and ReSound LiNX Quattro bring to you one of the best solutions in the industry. You’ll love the sound quality and noise reduction, rechargeable battery options, streaming, and phone app."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/linx",
    className: "c-md-a"
  }, "Read More"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
